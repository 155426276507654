import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { useLocation } from '@reach/router'

// Components
import HomeIcon from '../Icons/HomeIcon'

// Utils
import { toTitleCase } from '../../utils'

const Breadcrumbs = ({ breadArray }) => {
  const { pathname } = useLocation()
  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb justify-content-center align-middle">
        <li className="breadcrumb-item">
          <Link to="/">
            <HomeIcon />
          </Link>
        </li>
        {breadArray.length === 0 ? (
          <li className="breadcrumb-item text-nowrap active" aria-current="true">
            {toTitleCase(pathname)}
          </li>
        ) : (
          Object.keys(breadArray).map(key => {
            return breadArray[key].href === '' ? (
              <li key={key} className="breadcrumb-item text-nowrap active" aria-current="true">
                {toTitleCase(breadArray[key].name)}
              </li>
            ) : (
              <li key={key} className="breadcrumb-item">
                <Link to={`/${breadArray[key].href}`}>{toTitleCase(breadArray[key].name)}</Link>
              </li>
            )
          })
        )}
      </ol>
    </nav>
  )
}

Breadcrumbs.propTypes = {
  breadArray: PropTypes.array.isRequired,
}

export default Breadcrumbs
