import React from 'react'
import PropTypes from 'prop-types'

// Components
import Breadcrumbs from './Breadcrumbs'

const PageTitle = ({ title, is404, breadArray }) => {
  return (
    <div className="page-title py-4 mt-2 my-5">
      <div className="justify-content-center">
        <div className="text-center">
          <h1 className="h3 mb-2 mt-0">{title}</h1>
        </div>
        {(breadArray.length > 0 || is404 === false) && <Breadcrumbs breadArray={breadArray} />}
      </div>
    </div>
  )
}

PageTitle.defaultProps = {
  breadArray: [],
}

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  is404: PropTypes.bool.isRequired,
  breadArray: PropTypes.array,
}

export default PageTitle
