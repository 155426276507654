import React from 'react'
import PropTypes from 'prop-types'

// Components
import Seo from '../Seo'
import Header from '../Header'
import Footer from '../Footer'
import PageTitle from './PageTitle'
import Newsletter from '../Newsletter'

const PageLayout = ({ location, title, pageTitle, children, showNews, is404 }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location?.pathname === rootPath

  return (
    <>
      <Seo title={title} />
      <div data-is-root-path={isRootPath} className="d-flex flex-column min-vh-100">
        <Header />
        <main>
          <PageTitle title={pageTitle} is404={is404} />
          <section className="container">
            <div className="col-10 offset-1 col-lg-8 offset-lg-2 mb-5">{children}</div>
          </section>
          {showNews && <Newsletter />}
        </main>
        <Footer />
      </div>
    </>
  )
}

PageLayout.defaultProps = {
  showNews: false,
  is404: false,
}

PageLayout.propTypes = {
  location: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  pageTitle: PropTypes.string.isRequired,
  is404: PropTypes.bool,
  showNews: PropTypes.bool,
}

export default PageLayout
